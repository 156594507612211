import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'settings/inventory/asset-tags'

const getAssetTags = () => axios.get(`${endpoint}`)
const updateAssetTags = (ctx, data) => axios.post(`${endpoint}`, data)
const Actions = crudActions(endpoint)

export default {
  ...Actions,
  getAssetTags,
  updateAssetTags,
}
