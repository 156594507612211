<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import assetTagsModule from '@/store/settings/inventory/asset-tags'

export default {
  name: 'AssetTags',
  created() {
    this.$emit('updateMenu', 'settings-asset-tags')
  },
  setup() {
    const MODULE_NAME = 'asset-tags'

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, assetTagsModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>
